@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.logoBankID {
  display: block;
  margin: 0 auto;
}

.btnLogin {
  @extend %app-typography;

  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $app-green;
  border: none;
  padding: 12px;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  .btnLogoBankID {
    width: 21px;
    height: 24px;
    margin-right: 8px;
  }

  &:hover {
    background-color: $app-green-accent;
  }
}

.tips {
  @extend %text-sm;

  color: $app-blue-grey;
  margin-top: 20px;
  text-align: center;

  button {
    @extend %font-gibson, %text-sm;

    margin-top: 4px;
    border: none;
    background: none;
    cursor: pointer;
    color: $app-green;
    text-decoration: underline;

    &:hover {
      color: $app-green-accent;
    }
  }
}
