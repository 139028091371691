@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.hero {
  padding-top: 24px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1128px;
  margin: -84px auto 0;

  h1 {
    margin-bottom: 32px;
  }

  p {
    @extend %text-xl, %app-typography-p22;
  }
}

.loginText {
  width: 53.1914%;

  ul {
    margin-top: 0;

    & > li {
      @extend %text-base;

      text-align: left;
    }
  }
}

.rightSide {
  width: 38.2978%;
}

@media only screen and (max-width: 1100px) {
  .container {
    flex-direction: column-reverse;
    align-items: center;
    max-width: 100%;
    text-align: center;
  }

  .loginText {
    width: 100%;
    max-width: 600px;
  }

  .rightSide {
    width: 100%;
    max-width: 450px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
