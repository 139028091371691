@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.loginIframeWrapper {
  overflow: hidden;
  background-color: $app-light-biege;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.loginIframeWrapperTips {
  @extend %text-sm;

  margin-top: 20px;
  padding: 0 32px 32px;
  color: $app-blue-grey;
  text-align: center;

  button {
    @extend %font-gibson, %text-sm;

    margin-top: 4px;
    border: none;
    background: none;
    cursor: pointer;
    color: $app-green;
    text-decoration: underline;

    &:hover {
      color: $app-green-accent;
    }

    &:focus {
      outline: none;
    }
  }
}

.loginPopupWrapper {
  overflow: hidden;
  background-color: $app-light-biege;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 32px;
}
