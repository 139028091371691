@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  padding: 32px;
}

.containerWithIframe {
  height: 500px;
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 64px;
    height: 64px;
  }
}

.iframe {
  width: 100%;
  height: 100%;
}

.logoBankID {
  display: block;
  margin: 0 auto;
}

.btnLogin {
  @extend %app-typography;

  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $app-green;
  border: none;
  padding: 12px;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  .btnLogoBankID {
    width: 21px;
    height: 24px;
    margin-right: 8px;
  }

  &:hover {
    background-color: $app-green-accent;
  }
}
